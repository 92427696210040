import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap";


const Calculation = ({ roi, savings }) => {
  const [open, setOpen] = useState(true);
  const toggle = () => setOpen(!open);

  console.log(`roi`, roi)
  console.log(`savings`, savings)

  const closeBtn = (
    <button
      style={{ outline: "none", border: "none", backgroundColor: "#04244a", borderRadius: "10%" }}
      onClick={toggle}
    >
    <Badge>X</Badge>
    </button>
  
  );

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      contentClassName="theme-modal"
      size="lg"
    >
      <ModalHeader
        className="bg-light rounded-soft-top"
        toggle={toggle}
        close={closeBtn}
      >
      <div className="d-flex justify-content-between">
          <strong>Thank You for using BarnTalk!</strong>
        </div>
      </ModalHeader>
      <ModalBody className ="mt-4">
        <div>
          <p className="d-flex justify-content-center">
            Thanks! We estimate that you could be seeings as much as ${savings}{" "}
            in
          </p>
          <p className="d-flex justify-content-center">
            Annual Savings and ${roi} in Lifetime ROI with BarnTalk.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <h6>Ready to learn more? Call or email us today.</h6>
        </div>
        <ModalFooter className="d-flex justify-content-center mt-5" >
          <strong>515-220-2727</strong>
          {' '}|{' '}
          <strong>support@barntools.com</strong>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default Calculation;
