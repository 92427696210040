import React from 'react';
import Homepage from './columns/Homepage';

const Dashboard = () =>{
  
  return (
    <div>
      <Homepage />
    </div>
  )
}

export default Dashboard;