import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import WaitingForm from "./WaitingForm";

const QuestionsForm = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [q1Answer, setQ1Answer] = useState("");
  const [q2Answer, setQ2Answer] = useState("");
  const [q3Answer, setQ3Answer] = useState("");
  const [q4Answer, setQ4Answer] = useState("");

  const questions = [
    {
      value: 1,
      content: "What Do You Grow?",
    },
    {
      value: 2,
      content: "How many sites are in your operation?",
    },
    {
      value: 3,
      content: "Do you have an existing alarm in any of your barns?",
    },
    {
      value: 4,
      content:
        "Do you pay for a phone line, internet, or hotspot for your alarm?",
    },
  ];

  const [question, setQuestion] = useState(questions[0]);

  const handleOptionChange = (e) => {
    if (
      e.target.value === "Swine" ||
      e.target.value === "Poultry" ||
      e.target.value === "Other"
    ) {
      setQ1Answer(e.target.value);
      setDisable(false);
    } else if (
      e.target.value === "1-2" ||
      e.target.value === "3-5" ||
      e.target.value === "6-8" ||
      e.target.value === "8+"
    ) {
      setQ2Answer(e.target.value);
      setDisable(false);
    } else if (
      e.target.value === "Yes" ||
      e.target.value === "No" ||
      e.target.value === "Only In Some"
    ) {
      setQ3Answer(e.target.value);
      setDisable(false);
    } else if (e.target.value === "yes" || e.target.value === "no") {
      setQ4Answer(e.target.value);
      setDisable(false);
    }
  };

  const nextQuestion = (e, qust) => {
    e.preventDefault();
    if (qust.value === 1) {
      setQuestion(questions[1]);
      setDisable(true);
    }
    if (qust.value === 2) {
      setQuestion(questions[2]);
      setDisable(true);
    }
    if (qust.value === 3) {
      setQuestion(questions[3]);
      setDisable(true);
    }
    if (qust.value === 4) {
      //handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  };

  return (
    <Form>
      <legend className="mx-auto mt-2 text-center">{question.content}</legend>
      <hr></hr>
      <FormGroup className="d-flex justify-content-center mx-auto mt-2 mb-4">
        {question.value === 1 ? (
          <Row className="mt-3">
            <Col>
              <Input
                type="radio"
                value="Swine"
                checked={q1Answer === "Swine"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2" value="Swine">
                Swine
              </Label>{" "}
              <Input
                type="radio"
                value="Poultry"
                checked={q1Answer === "Poultry"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2" value="Poultry">
                Poultry
              </Label>{" "}
              <Input
                type="radio"
                value="Other"
                checked={q1Answer === "Other"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2" value="Other">
                Other
              </Label>
              <Col className="mx-auto mt-3 d-flex justify-content-center">
                <Button
                  className="btn-danger mt-4"
                  style={{ width: "8em", height: "2.5em" }}
                  onClick={(e) => nextQuestion(e, question)}
                  disabled={disable}
                >
                  Next
                </Button>
              </Col>
            </Col>
          </Row>
        ) : question.value === 2 ? (
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <Input
                type="radio"
                value="1-2"
                checked={q2Answer === "1-2"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2">1-2</Label>{" "}
              <Input
                type="radio"
                value="3-5"
                checked={q2Answer === "3-5"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2">3-5</Label>{" "}
              <Input
                type="radio"
                value="6-8"
                checked={q2Answer === "6-8"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2">6-8</Label>{" "}
              <Input
                type="radio"
                value="8+"
                checked={q2Answer === "8+"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-1">8+</Label>
            </Col>
            <p className="d-flex justify-content-center mt-4">
              <Button
                className="btn-danger"
                style={{ width: "8em", height: "2.5em" }}
                onClick={(e) => nextQuestion(e, question)}
                disabled={disable}
              >
                Next
              </Button>
            </p>
          </Row>
        ) : question.value === 3 ? (
          <Row className="mt-3">
            <Col>
              <Input
                type="radio"
                value="Yes"
                checked={q3Answer === "Yes"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2">Yes</Label>{" "}
              <Input
                type="radio"
                value="No"
                checked={q3Answer === "No"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2">No</Label>{" "}
              <Input
                type="radio"
                value="Only In Some"
                checked={q3Answer === "Only In Some"}
                onChange={(e) => handleOptionChange(e)}
              />
              <Label className="px-2">Only In Some</Label>
              <Col className="mx-auto mt-3 d-flex justify-content-center">
                <Button
                  className="btn-danger"
                  style={{ width: "8em", height: "2.5em" }}
                  onClick={(e) => nextQuestion(e, question)}
                  disabled={disable}
                >
                  Next
                </Button>
              </Col>
            </Col>
          </Row>
        ) : question.value === 4 ? (
          <div >
            <Row  className="mx-auto d-flex justify-content-center">
              <Col className="mt-4">
                <Input
                  type="radio"
                  value="yes"
                  checked={q4Answer === "yes"}
                  onChange={(e) => handleOptionChange(e)}
                />
                <Label className="px-2">Yes</Label>{" "}
                <Input
                  type="radio"
                  value="no"
                  checked={q4Answer === "no"}
                  onChange={(e) => handleOptionChange(e)}
                />
                <Label className="px-2">No</Label>
              </Col>
            </Row>
              <Col className="mt-5">
                <Button
                  className="btn-danger"
                  style={{ width: "8em", height: '2.5em' }}
                  onClick={(e) => handleSubmit(e)}
                  disabled={disable}
                >
                  Submit
                </Button>
              </Col>
          </div>
        ) : (
          <></>
        )}
      </FormGroup>
      {loading && (
        <WaitingForm
          q1Answer={q1Answer}
          q2Answer={q2Answer}
          q3Answer={q3Answer}
          q4Answer={q4Answer}
        />
      )}
    </Form>
  );
};

export default QuestionsForm;
