import React, { useState } from "react";
import {
  Button,
  Badge,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Row,
} from "reactstrap";
import emailjs, {init} from "emailjs-com";
import Calculation from './Calculation';

const WaitingForm = ({ q1Answer, q2Answer, q3Answer, q4Answer }) => {
  init("user_BFOgN7CWdXhwsMibUp2x4");
  const serv_id = "service_bk83wnq"
  const temp_id = "template_3hn9f19"
  const [open, setOpen] = useState(true);
  const toggle = () => setOpen(!open);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [roi, setRoi] = useState(0);
  const [savings, setSavings] = useState(0);
  
  let tempRoi = 0;
  let tempSavings = 0;

  const closeBtn = (
    <button
      style={{ outline: "none", border: "none", backgroundColor: "#04244a", borderRadius: "10%" }}
      onClick={toggle}
    >
    <Badge>X</Badge>
    </button>
  );

  const savingsAndRoiCalculator = () => {
    if (q2Answer === "1-2" && q4Answer === "yes") {
      tempRoi = 200;
      tempSavings = 2000;
    }
    if (q2Answer === "1-2" && q4Answer === "no") {
      tempRoi = 0;
      tempSavings = 2000;
    }
    if (q2Answer === "3-5" && q4Answer === "yes") {
      tempRoi = 400;
      tempSavings = 4000;
    }
    if (q2Answer === "3-5" && q4Answer === "no") {
      tempRoi = 0;
      tempSavings = 4000;
    }
    if (q2Answer === "6-8" && q4Answer === "yes") {
      tempRoi = 600;
      tempSavings = 6000;
    }
    if (q2Answer === "6-8" && q4Answer === "no") {
      tempRoi = 0;
      tempSavings = 6000;
    }
    if (q2Answer === "8+" && q4Answer === "yes") {
      tempRoi = 800;
      tempSavings = 8000;
    }
    if (q2Answer === "8+" && q4Answer === "no") {
      tempRoi = 0;
      tempSavings = 8000;
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    savingsAndRoiCalculator();
    setRoi(tempRoi)
    setSavings(tempSavings);
    const target = {
      name: name,
      number: number,
      email: email,
      q1Answer: q1Answer,
      q2Answer: q2Answer,
      q3Answer: q3Answer,
      q4Answer: q4Answer,
      roi: tempRoi,
      savings: tempSavings
    };
    console.log(`roi sendmail`, roi)
    console.log(`target.roi`, target.roi)
    emailjs.send(
      serv_id,
      temp_id,
      target
    ).then((res) => {
      console.log(`success!`, res.status, res.text);
      setLoading(false);
    }, (err) => {
      console.log(`err.text`, err.text)
    })

    
  };

  return (
    <>
      <Modal
        isOpen={open}
        toggle={toggle}
        contentClassName="theme-modal"
        size="lg"
      >
        {loading ? (
          <>
            <ModalHeader
              toggle={toggle}
              className="bg-light rounded-soft-top py-3 px-6 text-center"
              close={closeBtn}
            >
              While we run the BarnTalk Annual Savings and Lifetime ROI
              Calculator, we would love to get to know you better.
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={(e) => sendEmail(e)}>
                <Col sm={6} className="mx-auto">
                  <FormGroup className="mt-4">
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="mt-4">
                    <Input
                      type="text"
                      name="number"
                      placeholder="Mobile Number"
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="mt-4">
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col className="mx-auto">
                  <Row className="mt-4 mb-4">
                    <Button
                      className="btn-danger mx-auto"
                      style={{ width: "8em", height: '2.5em' }} 
                    >
                      Submit
                    </Button>
                  </Row>
                </Col>
              </Form>
            </ModalBody>
          </>
        ) : (
          <Calculation roi={roi} savings={savings} />
        )}
      </Modal>
    </>
  );
};

export default WaitingForm;
