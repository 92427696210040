import React, { useState } from "react";
import { Badge, Button, Modal, ModalBody,  ModalHeader} from "reactstrap";
import QuestionsForm from "./QuestionsForm";


const FormModal = () => {
  const [open, setOpen] = useState(true);
  const toggle = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  const closeBtn = (
    <button
      style={{ outline: "none", border: "none", backgroundColor: "#04244a", borderRadius: "10%" }}
      onClick={toggle}
    >
    <Badge>X</Badge>
    </button>
  );

  return (
    <div>
      <Modal
        isOpen={open}
        toggle={toggle}
        size="lg"
      >
        {loading ? (
          <>
          <ModalHeader className="bg-light rounded-soft-top text-center mb-3" close={closeBtn}>
            The next generation wireless, smart barn alarm. The easiest to use tech you'll ever put in your barn
          </ModalHeader>
          <ModalHeader className="py-3 px-4 mx-auto text-center mb-3">
            Are you ready to calculate your Annual Savings and Lifetime ROI?
          </ModalHeader>
          <ModalBody>
          <Button
              size="lg"
              className="d-flex justify-content-center w-25 mx-auto btn-danger "
              onClick={() => setLoading(false)}
              style={{height: "50px", alignItems: "center"}}
            >Let's Get Started</Button>
          </ModalBody>
          </>
        ) : (
          <ModalBody className="py-3 px-4 mx-auto">
            <QuestionsForm />
            
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

export default FormModal;
